
$('#ftv-file-delete').on('submit', function () {
    

    if (!confirm("Si è sicuri di voler cancellare il file? tutte le righe ad esso associate verranno cancellate.")) 
    {
        event.preventDefault();
    } 
})


$('#form-domestico').on('submit', function() {
    setTimeout(() => {
        location.reload();
    }, 2000);
});
