import { Loader } from "@googlemaps/js-api-loader";


$('body.ftv.ftv-sede').each(() => {

  const gmLoader = new Loader({
    apiKey: document.head.querySelector('meta[name="google-apikey"]').content,
    version: "weekly",
  });

  function showInfoModal(title, body, btnOkCbk) {
    const modal = $('#infoModal');
    modal.find('.modal-title').text(title);
    modal.find('.modal-body').html(body);
    if (btnOkCbk) {
      modal.find('.btnOk').one(function () {
        btnOkCbk(modal);
      })
        .show();
    } else {
      modal.find('.btnOk').hide();
    }
    return modal.modal('show');
  }

  $('.showAttLogBtn').on('click', function () {
    const btn = $(this);
    showInfoModal('Log attivazione', btn.next().html());
  });

  $('.showModAttLogBtn').on('click', function () {
    const btn = $(this);
    showInfoModal('Log moduli attivati', btn.next().html());
  });

  $('.showModNonAttLogBtn').on('click', function () {
    const btn = $(this);
    showInfoModal('Log moduli non attivati', btn.next().html());
  });

  $('#sedeMap').each(function () {
    const lat = $('#sedeMap').data('lat');
    const lng = $('#sedeMap').data('lng');
    console.log('Map', lat, lng);
    gmLoader.load().then(() => {
      const position = { lat: lat, lng: lng };
      // Create map
      const map = new google.maps.Map(document.getElementById("sedeMap"), {
        center: position,
        zoom: 10,
      });

      // Show marker
      const marker = new google.maps.Marker({
        position: position,
        map: map,
        title: `${position.lat}, ${position.lng}`
      });
    });
  });

  function getFullAddress() {
    const addressComponents = [];

    ['#indirizzo', '#numero_civico', '#provincia', '#cap', '#comune'].forEach(elementId => {
      const value = $(elementId).val();
      if (value) {
        addressComponents.push(value);
      }
    });

    return addressComponents.join(',');
  }

  $('#addressGeocodingMap').each(function () {
    // console.log('x1');
    gmLoader.load().then(() => {
      // console.log('x2');

      // Init Geocoder
      const GEOCODER = new google.maps.Geocoder();
      console.log('GEOCODER', GEOCODER);

      // Init Map
      const map = new google.maps.Map(document.getElementById("addressGeocodingMap"), {
        center: { lat: 45, lng: 45 },
        zoom: 1,
      });

      let posMarker;

      $('.btnValidaIndirizzo').on('click', function () {
        const searchAddress = getFullAddress();
        if (searchAddress === '') {
          return;
        }
        GEOCODER.geocode({
          address: searchAddress,
        }, (results, status) => {
          console.log(status, results);
          if (status === 'OK') {
            const location = results[0].geometry.location;

            $('#lat').val(location.lat);
            $('#lng').val(location.lng);
            if (!posMarker) {
              posMarker = new google.maps.Marker({
                position: location,
                map: map,
                title: `${location.lat}, ${location.lng}`,
                draggable: true
              });
              posMarker.addListener('dragend', () => {
                const position = posMarker.getPosition();
                $('#lat').val(position.lat());
                $('#lng').val(position.lng());
              })
            } else {
              // move marker
              posMarker.setPosition(location);
              posMarker.setTitle(`${location.lat}, ${location.lng}`);
            }
            map.panTo(location);
            map.setZoom(15);
          }
        });
      });

    });
  });

  // Activate Autocomplete
  const produttoreAutocomplete = $('#produttore');
  const selected_produttore_value = $('#produttore').data('selected-value');
  const selected_produttore_text = $('#produttore').data('selected-text');
  console.log(selected_produttore_value, selected_produttore_text);
  produttoreAutocomplete.autoComplete();
  if (selected_produttore_value) {
    produttoreAutocomplete.autoComplete('set', { value: selected_produttore_value, text: selected_produttore_text });
  }


  $('.btnCancelActivation').on('click', async function () {
    const url = $(this).data('url');
    // TODO - Use a fancy and beautiful modal
    const res = window.confirm("ATTENZIONE. Procedere all'annullamento dell'attivazione?");
    if (res) {
      // console.log(url);
      const result = await axios.delete(url);
      // console.log(result);
      if (result.data.result === 'OK') {
        window.location.reload();
      }
    }

  });

  $('.btnCancelActivation').on('click', async function () {
    const url = $(this).data('url');
    // TODO - Use a fancy and beautiful modal
    const res = window.confirm("ATTENZIONE. Procedere all'annullamento dell'attivazione?");
    if (res) {
      // console.log(url);
      const result = await axios.delete(url);
      // console.log(result);
      if (result.data.result === 'OK') {
        window.location.reload();
      }
    }

  });

});
