const { default: axios } = require("axios");

$('body.rdr.batteries-create').each(() => {
    console.log(CATALOGTREE);
    let selBrandId = $('#brand_id').val(),
        selModelName = $('#model_name').val(),
        selYear = $('#year').val(),
        selBatteryBrand = $('#battery_brand').val(),
        selBatteryModelName = $('#battery_model_name').val();
    const selectModal = $('#selectModal');

    refreshEnabledDisabledStatus();

    if ((Object.keys(CATALOGTREE).length == 1) && !$('#brand_id').val()) {
        setTimeout(() => {
            $('#brand_id').val(Object.keys(CATALOGTREE)[0]);
            $('#brand_id option:contains(FCA)').prop('selected', true);
            $('#brand_id').trigger('change');
        }, 10);
    }

    $('#brand_id').on('change', function () {
        selectBrand($(this).val());
        refreshEnabledDisabledStatus();

        // TODO: per demo
        if ($('#brand_id option:selected').text().includes('FCA ')) {
            $('.search-vin').removeClass('d-none');
        } else {
            $('.search-vin').addClass('d-none');
        }
    });

    $('#model_name-searchBtn').on('click', function () {
        prepareSelectModal('model_name', 'selectModelNameBtn',
            'Modello',
            CATALOGTREE[selBrandId]);

        selectModal.modal('show');
    });

    selectModal.on('click', '.selectModelNameBtn', function () {
        const val = $(this).data('value');
        console.log(val);
        selectModelName(val);
        selectModal.modal('hide');
        refreshEnabledDisabledStatus();
    });

    $('#year-searchBtn').on('click', function () {
        prepareSelectModal('year', 'selectYearBtn',
            'Anno Automobile',
            CATALOGTREE[selBrandId][selModelName]);

        selectModal.modal('show');
    });

    selectModal.on('click', '.selectYearBtn', function () {
        const val = $(this).data('value');
        // console.log(val);
        selectYear(val);
        selectModal.modal('hide');
        refreshEnabledDisabledStatus();
    });

    $('#battery_brand-searchBtn').on('click', function () {
        prepareSelectModal('battery_brand', 'selectBatteryBrandBtn',
            'Marca Batteria',
            CATALOGTREE[selBrandId][selModelName][selYear]);

        selectModal.modal('show');
    });

    selectModal.on('click', '.selectBatteryModelNameBtn', function () {
        const val = $(this).data('value');
        // console.log(val);
        selectBatteryModelName(val);
        selectModal.modal('hide');
        refreshEnabledDisabledStatus();
    });

    $('#battery_model_name-searchBtn').on('click', function () {
        prepareSelectModal('battery_model_name', 'selectBatteryModelNameBtn',
            'Generazione Batteria',
            CATALOGTREE[selBrandId][selModelName][selYear][selBatteryBrand]);

        selectModal.modal('show');
    });

    selectModal.on('click', '.selectBatteryBrandBtn', function () {
        const val = $(this).data('value');
        // console.log(val);
        selectBatteryBrand(val);
        selectModal.modal('hide');
        refreshEnabledDisabledStatus();
    });

    selectModal.on('click', '.customDataEditBtn', function () {
        const fieldName = $(this).data('fieldname');
        // console.log(val);
        enableCustomDataEditing(fieldName);
        selectModal.modal('hide');
    });


    function selectBrand(brandId) {
        selBrandId = brandId;
        selectModelName(null);
    }

    function selectModelName(modelName) {
        selModelName = modelName;
        $('#model_name').val(selModelName);
        selectYear(null);
        if (selBrandId && selModelName) {
            // console.log('here');
            const years = Object.keys(CATALOGTREE[selBrandId][selModelName]);
            if (years.length == 1) {
                // Select single year 0
                selectYear(years[0]);
            }
        }
    }

    function selectYear(year) {
        selYear = year;
        $('#year').val(selYear);
        selectBatteryBrand(null);
        if (selYear) {
            // console.log('here');
            const batteryBrands = Object.keys(CATALOGTREE[selBrandId][selModelName][selYear]);
            if (batteryBrands.length == 1) {
                // Select single year 0
                selectBatteryBrand(batteryBrands[0]);
            }
        }
    }

    function selectBatteryBrand(batteryBrand) {
        selBatteryBrand = batteryBrand;
        $('#battery_brand').val(selBatteryBrand);
        selectBatteryModelName(null);
        if (selBatteryBrand) {
            // console.log('here');
            const batteryModels = Object.keys(CATALOGTREE[selBrandId][selModelName][selYear][selBatteryBrand]);
            if (batteryModels.length == 1) {
                // Select single year 0
                selectBatteryModelName(batteryModels[0]);
            }
        }
    }

    function selectBatteryModelName(batteryModelName) {
        selBatteryModelName = batteryModelName;
        $('#battery_model_name').val(selBatteryModelName);
        // Complete other fields
        if (selBrandId && selModelName && selYear
            && selBatteryBrand && selBatteryModelName) {

            const batteryData = CATALOGTREE[selBrandId][selModelName]
            [selYear][selBatteryBrand]
            [selBatteryModelName];

            console.log(batteryData);
            $('#peso').val(batteryData.weight).prop('readonly', true);
            $('#width').val(batteryData.width).prop('readonly', true);
            $('#height').val(batteryData.height).prop('readonly', true);
            $('#depth').val(batteryData.depth).prop('readonly', true);
            $('#chemical_family').val(batteryData.chemical_family);
            $('#chemical_family option:not(:selected)').prop('disabled', true);
            $('#battery_type').val(batteryData.battery_type).prop('readonly', true);
            $('#battery_type option:not(:selected)').prop('disabled', true);
            // hidden id of battery
            $('#battery_catalog_id').val(batteryData.id);
            $('.alertCustomBattery').addClass('d-none');

            if (batteryData.prefix != null) {
                $('#serial_number-prefix').show();
                $('#serial_number-prefix-text').empty().append(batteryData.prefix);
                $('#serial_number-prefix-input').val(batteryData.prefix);
            } else {
                $('#serial_number-prefix').hide();
                $('#serial_number-prefix-input').val('');
            }

            if (batteryData.suffix != null) {
                $('#serial_number-suffix').show();
                $('#serial_number-suffix-text').empty().append(batteryData.suffix);
                $('#serial_number-suffix-input').val(batteryData.suffix);
            }  else {
                $('#serial_number-suffix').hide();
                $('#serial_number-suffix-input').val('');
            }
        }
    }

    function enableCustomDataEditing(fieldName) {
        // Attenzione. Per abilitare l'editing in cascata
        // usiamo lo switch senza break. Mettendo i campi in fila
        console.log(fieldName);
        switch (fieldName) {
            case 'model_name':
                $(`#model_name`).attr('readonly', false);
                selectModelName(null);

            case 'year':
                $(`#year`).attr('readonly', false);
                selectYear(null);

            case 'battery_brand':
                $(`#battery_brand`).attr('readonly', false);
                selectBatteryBrand(null);

            case 'battery_model_name':
                $(`#battery_model_name`).attr('readonly', false);
                selectBatteryModelName(null);
        }

        // hidden id of battery
        $('#battery_catalog_id').val('');
        $('.alertCustomBattery').removeClass('d-none');
    }


    function refreshEnabledDisabledStatus() {
        console.log(selBrandId, selModelName, selYear, selBatteryBrand, selBatteryModelName);

        // $('#model_name').attr('readonly', true);
        // if (!selBrandId) {
        //     $('#model_name-searchBtn').attr('disabled', true);
        // } else {
        //     $('#model_name-searchBtn').attr('disabled', false);
        // }

        // $('#year').attr('readonly', true);
        // if (!selModelName) {
        //     $('#year-searchBtn').attr('disabled', true);
        // } else {
        //     $('#year-searchBtn').attr('disabled', false);
        // }

        // $('#battery_brand').attr('readonly', true);
        // if (!selYear) {
        //     $('#battery_brand-searchBtn').attr('disabled', true);
        // } else {
        //     $('#battery_brand-searchBtn').attr('disabled', false);
        // }

        // $('#battery_model_name').attr('readonly', true);
        // if (!selBatteryBrand) {
        //     $('#battery_model_name-searchBtn').attr('disabled', true);
        // } else {
        //     $('#battery_model_name-searchBtn').attr('disabled', false);
        // }

    }

    /**
     * Prepare the select Modal
     */
    function prepareSelectModal(fieldName, selectBtnName,
        fieldDescription, optionsObj) {

        const optionButtons = [];
        if (optionsObj) {
            const options = Object.keys(optionsObj);
            options.sort();
            for (const optionName of options) {
                optionButtons.push(
                    $('<div>').addClass('col-md-4')
                        .html([
                            $('<button>').addClass(`btn btn-block btn-outline-primary mb-2 ${selectBtnName}`)
                                .data('value', optionName)
                                .html(optionName),
                        ])
                )
            }
        }

        selectModal.find('.modal-title').text(`Seleziona ${fieldDescription}`);
        selectModal.find('.modal-body').html([
            `<p>Seleziona ${fieldDescription} dalla lista seguente</p>`,
            $('<div>').addClass('row')
                .html(optionButtons),
            $('<hr>'),
            `<p>${fieldDescription} non è presente? Puoi inserire manualmente i dati mancanti.</p>`,
            $('<div>').addClass('row').html([
                $('<div>').addClass('col-md-4 offset-md-4').html(
                    $('<button>').addClass(`btn btn-block btn-outline-danger customDataEditBtn`)
                        .data('fieldname', fieldName)
                        .html("Inserisci dati non presenti")
                )
            ])
        ]);

    }

    // in caso di "voglio ordinare da COBAT" mostro la modal informativa e blocco i campi sottostanti inerenti al packaging
    $('input[name=packaging][value=2]').on('click', function () {
        $('#pkgInfoModal').modal('show');
        packagingInputPropSwitch(true)
        // mostro il testo esplicativo.
        $('#warning-packaging-text').css('display', 'flex');
    });

    // se l'utente è in possesso del packaging gli si  sbloccano i campi e si nasconde il testo.
    $('input[name=packaging][value=1]').on('click', function () {
        packagingInputPropSwitch(false)
        // nascondo il testo esplicativo.
        $('#warning-packaging-text').hide();
    });


    // in caso di edit di una batteria, potrebbe essere in stato PENDING e quindi
    // inserita dall'utente. Sblocchiamo i campi per l'editing.
    // Li sblocchiamo tutti da model_name in poi.
    if (window.CUSTOMBATTERYMODE) {
        $(`#model_name`).attr('readonly', false);
        $(`#year`).attr('readonly', false);
        $(`#battery_brand`).attr('readonly', false);
        $(`#battery_model_name`).attr('readonly', false);
        // hidden id of battery
        $('#battery_catalog_id').val('');
        $('.alertCustomBattery').removeClass('d-none');
    }

    // cambia la proprietà disabled del blocco di input
    function packagingInputPropSwitch(value) {

        $('#packaging_type').prop('disabled', value);
        $('#packaging_desc').prop('disabled', value);
        $('#packaging_weight').prop('disabled', value);
        $('#packaging_width').prop('disabled', value);
        $('#packaging_height').prop('disabled', value);
        $('#packaging_depth').prop('disabled', value);
        $('#packaging_nr_omologazione').prop('disabled', value);
        $('#packaging_photos').prop('disabled', value);
        $('#packaging_antistatic').prop('disabled', value);
        $('#packaging_mr_non_conduttivo').prop('disabled', value);
        $('#packaging_mr_non_combustibile').prop('disabled', value);

    }

    // Interface animation, green yellow red.
    // Il JS seguente era già presente nel codice del branch batteries fatto da gmartino
    // va verificato, ripulito e adeguato
    function returnStatusBattery() {
        let batteryDamaged = parseInt($('input[name="accident"]:checked').val());
        let batteryStable = parseInt($('input[name="stable"]:checked').val());
        if (batteryDamaged && batteryStable) {
            return "yellow";
        } else if (!batteryDamaged && batteryStable) {
            return "green";
        } else {
            return "red";
        }
    }
    console.log($('input[name="accident"]:checked').val(), $('input[name="stable"]:checked').val());

    $(".battery-security").on("click", function () {

        if (!$('input[name="accident"]:checked').val() || !$('input[name="stable"]:checked').val()) {
            $(".battery-status").hide();
            $(".battery-damage").hide();
            $(".battery-files").hide();
        } else {
            $(".battery-status").show();
            status = returnStatusBattery();
            switch (status) {
                case "green":
                    $("#sicurezza").val("GREEN");
                    $(".battery-img").css("color", "#47c682");
                    $(".battery-damage").hide();
                    $(".battery-files").show();
                    $("#damage").prop("required", false);
                    // mostro le opzioni del packaging disponibili per le batterie verdi 
                    $('#packaging_type option').hide();
                    $('#packaging_type option[data-color="GREEN"]').show();
                    $('#packaging_type option[data-color=""]').show();

                    break;
                case "red":
                    $("#sicurezza").val("RED");
                    $(".battery-img").css("color", "#eb3d5a");
                    $(".battery-damage").show();
                    $(".battery-files").show();
                    $("#damage").prop("required", true);
                    // mostro le opzioni del packaging disponibili per le batterie verdi 
                    $('#packaging_type option').hide();
                    $('#packaging_type option[data-color="RED"]').show();
                    $('#packaging_type option[data-color=""]').show();
                    
                    break;
                case "yellow":
                    $("#sicurezza").val("YELLOW");
                    $(".battery-img").css("color", "#f3a75c");
                    $(".battery-damage").show();
                    $(".battery-files").show();
                    $("#damage").prop("required", true);
                    // mostro le opzioni del packaging disponibili per le batterie verdi 
                    $('#packaging_type option').hide();
                    $('#packaging_type option[data-color="YELLOW"]').show();
                    $('#packaging_type option[data-color=""]').show();
                    break;
                default:
                    $("#sicurezza").val("");
                    $(".battery-img").css("color", "black");
                    break;
            }
        }
    })

    $(".battery-security[name=stable]:checked").trigger('click');

    $('#search_vin-searchBtn').on('click', async function () {
        const url = '/api/batteries/fca';
        const vin = $('#search_vin').val();
        if (vin) {
            const response = await axios.get(url, { params: { vin } });
            if (response.data && response.data.total) {
                const batteryData = response.data.results[0];
                console.log(batteryData);
                if (batteryData.status != 'ACTIVE') {
                    alert('ATTENZIONE! La batteria associata a questo vin non è disponibile al ritiro.');
                    $('#vin_seriale').val('');
                    $('#model_name').val('');
                    $('#year').val('');
                    $('#battery_brand').val('');
                    $('#battery_model_name').val('');
                    $('#peso').val('').prop('readonly', true);
                    $('#serial_number').val('').prop('readonly', true);
                } else {
                    $('#vin_seriale').val(vin);
                    $('#model_name').val(`${batteryData.brandName} ${batteryData.modelName} ${batteryData.versionName}`);
                    $('#year').val(batteryData.batteryanagctionDate.split('-')[0]);
                    $('#battery_brand').val(batteryData.supplierCode);
                    $('#battery_model_name').val('-');
                    $('#peso').val(batteryData.batteryWeight).prop('readonly', true);
                    $('#serial_number').val(batteryData._id).prop('readonly', true);
                }
            } else {
                alert('ATTENZIONE! La batteria associata a questo vin non è disponibile al ritiro.');
            }
        }
    });


   $('#campaign_key-searchBtn').on('click', async function () {
        const vin = $('#campaign_key').val();
        const url = '/api/campagna/key/' + vin;
        const casaMadreId = $('#anagrafica_id').val();
        if (vin) {
            const response = await axios.get(url);
            if (response.data) {
                console.log(response.data);
                const campaignData = response.data.data;
                if ('id' in campaignData && campaignData.anagrafica_id == casaMadreId &&  campaignData.attivo == '1') {
                    $('#campaign-box-ok').removeClass('d-none');
                    $('#campaign-box-ko').addClass('d-none');
                    $('#submit-btn').prop('disabled', false);
                    $('#campaign_key_check').val(campaignData.key);
                    console.log($('#campaign_key_check').val());
                } else {
                    $('#campaign-box-ko').removeClass('d-none');
                    $('#campaign-box-ok').addClass('d-none');
                    $('#submit-btn').prop('disabled', true);
                    $('#campaign_key_check').val('');
                }
            } else {
                $('#campaign-box-ko').removeClass('d-none');
                $('#campaign-box-ok').addClass('d-none');
                $('#submit-btn').prop('disabled', true);
                $('#campaign_key_check').val('');
            }
        }
    }); 

    $('#packaging_type').on('change', async function () {
        const packaging = $('#packaging_type').val();
        const url = '/api/packaging/get/' + packaging;
        if (packaging) {
            const response = await axios.get(url);
            if (response.data) {
                console.log(response.data);
                const res = response.data;
                $('#packaging_desc').val(res.description);
                if (typeof(res.pic) != "undefined" && res.pic !== null) 
                {
                    $('#img-container').show().attr("src",res.pic);
                } else 
                {
                    $('#img-container').hide();
                }
                $('#packaging_weight').val(res.weight);
                $('#packaging_width').val(res.width);
                $('#packaging_height').val(res.height);
                $('#packaging_depth').val(res.depth);
                $('#packaging_nr_omologazione').val(res.nr_omologazione);

                if (res.id == '1') {
                    // $('#packaging_nr_omologazione').attr('required', true);
                    // $('#packaging_nr_omologazione-label').empty().append("Seriale contenitore *");
                } else {
                    $('#packaging_nr_omologazione').attr('required', false);
                    $('#packaging_nr_omologazione-label').empty().append("Seriale contenitore");

                }
            } else {
            }  
        }
    });


    // prevent multiple submit
    $('#battery-form').on('submit', function () {
        $('#submit-btn').hide();
        $('#loading-btn').show()

        setTimeout(() => {
            $('#submit-btn').show();
            $('#loading-btn').hide()
        }, 15000);
    });
});

$('body.rdr.show.batteries').each(() => {
    $('.btnDeleteBattery').on('click', async function () {
        const btn = $(this);
        const deleteUrl = btn.data('url');

        // TODO - Use a fancy and beautiful modal
        const res = window.confirm("ATTENZIONE. Eliminare la batteria dalla Richiesta di Ritiro?\nL'operazione è irreversibile.");
        if (res) {
            // console.log(url);
            try {
                const result = await axios.delete(deleteUrl);
                if (result.data.result === 'OK') {
                    if (result.data.redirect) {
                        window.location.assign(result.data.redirect);
                    } else {
                        window.location.reload();
                    }
                }
            } catch (error) {
                console.error(error);
                window.alert("Si è verificato un errore: " + error);
            }
        }
    });
});

var serialTid = null;

$('#serial_number').on('keyup', function () {
    
    if ($('#brand_cf').val() == '11650200964') {
        
        var count = $('#serial_number').val().length;
        
        clearTimeout(serialTid);
        serialTid = setTimeout(() => {
            if (count < 10 || count > 10) {
                $('#serial_number_helper').remove();
                $('#serial_number-input-group').after('<small id="serial_number_helper" class="form-text text-muted">Il numero seriale da inserire a vostra cura è di 10 caratteri</small>');
            } else {
                $('#serial_number_helper').remove();
            }
        }, 500);
    }
})