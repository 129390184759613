import Handsontable from "handsontable";

$('body.ftv.ftv-search').each(() => {
  function showNotFound() {
    $('.single-result.not-found').removeClass('d-none');
    $('.single-result.found').addClass('d-none');
  }

  function showSingleResult(record) {
    $('.single-result.not-found').addClass('d-none');

    const srElem = $('.single-result.found');
    console.log(record.status);
    switch (record.status) {
      case 'ACTIVE':
        srElem.find('.stato-modulo').html(
          $('<span>').addClass('text-info').text("NON INSTALLATO")
        );

        break;

      case 'INSTALLATO':
        srElem.find('.stato-modulo').html(
          $('<span>').addClass('text-success').text("INSTALLATO")
        );

        break;

      case 'RIMBORSATO':
        srElem.find('.stato-modulo').html(
          $('<span>').addClass('text-error').text("RIMBORSATO")
        );

        break;

      default:
        srElem.find('.stato-modulo').html(
          $('<span>').addClass('text-error').text("NON DICHIARATO")
        );
        break;
    }

    const createTs = new Date(record.create_ts);
    if (!isNaN(createTs)) {
      srElem.find('.anno-modulo').text(createTs.getFullYear());
    } else {
      srElem.find('.anno-modulo').text('n.d.');
    }
    if (record.installazione_ts) {
      const installazioneTs = new Date(record.installazione_ts);
      srElem.find('.attivazione-modulo').text(`${installazioneTs.getDay()}.${installazioneTs.getMonth()}.${installazioneTs.getFullYear()}`);
    } else {
      srElem.find('.attivazione-modulo').text('');
    }
    // Visualizza sede
    const btnViewSede = srElem.find('.btnViewSede');
    if (record.attivazioneUrl) {
        btnViewSede.attr('href', record.attivazioneUrl).removeClass('d-none');
    } else {
        btnViewSede.attr('href', '').addClass('d-none');
    }

    srElem.removeClass('d-none');
  }

  async function performSerialCheck(piva, serial, okCbk, koCbk) {
    const url = SEARCH_URL.replace('==PIVA==', piva).replace('==SERIALE==', serial);
    // console.log(`Searching ${url}`);
    let response;
    try {
        response = await axios.get(url);
        console.log(response);
        okCbk(response.data);
    } catch (error) {
        koCbk(error);
        return false
    }
    return response
    // const promise = $.ajax(
    //   url
    // )
    // .done((res) => {
    //     // console.log(res);
    //     okCbk(res);
    // })
    // .fail((err) => {
    //     // console.error(err);
    //     koCbk(err);
    // });

    // return promise;
  }

  $('.btnSearchSingle').on('click', function () {
    const baseEl = $('.ftv-single-search');
    const piva = baseEl.find('#produttore_piva').val();
    const serial = baseEl.find('#serial').val();
    if (piva && serial) {
      performSerialCheck(piva, serial,
        showSingleResult, showNotFound
      );
    }

  });

  const multiData = [
    ['', ''],
  ];
  const searchTable = new Handsontable($('.search-table')[0], {
    data: multiData,
    width: '100%',
    rowHeaders: true,
    licenseKey: 'non-commercial-and-evaluation'
  });
  Handsontable.hooks.add('afterChange', async (changes) => {
    // console.log('changed:', changes);
    // console.log(multiData);

    for (const change of changes) {

      const rowNum = change[0];
      const colNum = change[1];
      const piva = multiData[rowNum][0];
      const serial = multiData[rowNum][1];

      // if not modified skip
      if ((change[2] == change[3]) || (colNum > 1)) {
        return;
      }

      if (piva && serial) {
        try {
          await performSerialCheck(piva, serial, (record) => {
            // console.log(record);
            switch (record.status) {
              case 'ACTIVE':
                searchTable.setDataAtCell(rowNum, 2, 'NON INSTALLATO');
                searchTable.setCellMeta(rowNum, 1, 'className', 'ht-serial-non-installato');
                break;

              case 'INSTALLATO':
                searchTable.setDataAtCell(rowNum, 2, 'INSTALLATO');
                searchTable.setCellMeta(rowNum, 1, 'className', 'ht-serial-attivo');
                break;

              case 'RIMBORSATO':
                searchTable.setDataAtCell(rowNum, 2, 'RIMBORSATO');
                searchTable.setCellMeta(rowNum, 1, 'className', 'ht-serial-non-trovato');
                break;

              default:
                searchTable.setDataAtCell(rowNum, 2, 'ERRORE');
                searchTable.setCellMeta(rowNum, 1, 'className', 'ht-serial-non-trovato');
                break;
            }
            searchTable.render();

          }, (err) => {
            switch (err.status) {
              case 404:
                searchTable.setDataAtCell(rowNum, 2, 'NON DICHIARATO');
                searchTable.setCellMeta(rowNum, 1, 'className', 'ht-serial-non-trovato');
                break

              case 429:
                // rate limited
                searchTable.setDataAtCell(rowNum, 2, 'Errore richiesta');
                searchTable.setCellMeta(rowNum, 1, 'className', '');
            }
            // console.error('NOT FOUND');
          });
        } catch (error) { }
        // sleep to rate limit requests
        // await ((ms) => {return new Promise(resolve => setTimeout(resolve, ms))})(200);
      } else {
        // clean
        searchTable.setCellMeta(rowNum, 1, 'className', '');
        searchTable.setDataAtCell(rowNum, 2, '');
      }
    };

  }, searchTable);

  window.searchTable = searchTable;

});
