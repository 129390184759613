$('body.rdr.ftv-create').each(() => {


    $('#search_serial-searchBtn').on('click', async function () {
        const url = '/api/ftv/serial';
        const serial = $('#search_serial').val();
        if (serial) {
            console.log(serial);
            const response = await axios.get(url, {
                params: {
                    serial
                }
            });
            if (response) {
                if (response.data) {
                    //  aggiungo linea alla table con i dati del pannello - check su sede  = alle altre
                    console.log(response.data);

                    if ($('#' + response.data.seriale).length == 0) {

                        if (response.data.sedeAttivazione) {
                            $('#ftv-select-table').append(
                                '<tr data-sede="' + response.data.sedeAttivazione.id + '"><td> <input type="checkbox" class="moduli" name="moduli[]" data-peso="' + response.data.peso + ' " data-sede="' + response.data.sedeAttivazione.id + '" value="' + response.data.seriale + '" id="' + response.data.seriale + '">  </td> <td> ' + response.data.seriale + ' </td> <td> ' + response.data.ragioneSociale + ' </td> <td> ' + response.data.listino[0].categoria.name + ' </td> <td> ' + response.data.listino[0].sottocategoria.name + ' </td> <td> ' + response.data.listino[0].tipologia.name + ' </td> <td> ' + response.data.sedeAttivazione.indirizzo + ' ' + response.data.sedeAttivazione.numero_civico + '. ' + response.data.sedeAttivazione.comune + '</td> <td> ' + response.data.peso + ' </td> </tr>'
                            );

                            // $('<tr>').data('sede', response.data.sedeAttivazione.id)
                            // .html([
                            //     $('<td>').html([
                            //         $('<input>').data('sede', response.data.sedeAttivazione.id).val(response.data.seriale),
                            //     ]),
                            //     $('<td>')
                            // ]);

                        } else {
                            $('#alert-box').show();
                            $('#alertBox').empty();
                            $('#alertBox').append(
                                `Il seriale ${response.data.seriale} risulta installato ma non è possibile rinvenire la sede d'installazione. Se il problema persiste contattare l'amministratore di sistema.`
                            )
                        }


                        var isPresent = false;
                        $("#sedi_terze_option option").each(function () {
                            var thisOptionValue = $(this).val();

                            if (thisOptionValue == response.data.sedeAttivazione.id) {
                                isPresent = true;
                            }
                        });

                        if (!isPresent) {
                            $('#sedi_terze_option').append(
                                '<option value="' + response.data.sedeAttivazione.id + '"> ' + response.data.sedeAttivazione.indirizzo + ' ' + response.data.sedeAttivazione.numero_civico + ' ' + response.data.sedeAttivazione.comune + ' ' + response.data.sedeAttivazione.cap + ' (' + response.data.sedeAttivazione.provincia + ') (sede installazione) </option>'
                            );
                        }

                        // ' + response.data.sedeAttivazione.indirizzo + ' ' + response.data.sedeAttivazione.numero_civico + ' ' + response.data.sedeAttivazione.comune  + ' ' + response.data.sedeAttivazione.cap  +' (' + response.data.sedeAttivazione.provincia + ')
                    } else {
                        $('#alert-box').show();
                        $('#alertBox').empty();
                        $('#alertBox').append(
                            'il seriale: ' + response.data.seriale + ' inserito risulta già presente.'
                        )
                    }
                }
            }
        }
    });

    $('#close-alert').on('click', function () {
        $('#alert-box').hide();

    });


    $('#sedeRitiroPropria').on('change', function () {
        $('#sedi_proprie').show().attr('disabled', false);
        $('#sedi_terze').hide().attr('disabled', true);

    });

    $('#sedeRitiroTerza').on('change', function () {
        $('#sedi_proprie').hide().attr('disabled', true);
        $('#sedi_terze').show().attr('disabled', false);
    });


    var pesoMax = 100;
    $('#verify-rdr').on('click', function () {
        if ($('#sedeRitiroPropria').is(':checked')) {

            if ($('#sedi_proprie_option').val() != '') {
                // la sede è selezionata e posso proseguire con la verifica del peso.

                var pesoModuli = 0;
                $('.moduli ').each(function () {
                    console.log($(this).is(':checked'));
                    if ($(this).is(':checked')) {
                        pesoModuli += parseInt($(this).data('peso'));
                    }
                });

                if (pesoModuli < pesoMax && pesoModuli != 0) {
                    $('#verify-rdr').hide();
                    $('#conf-rdr').show();

                    // dopo la verifica non si possono modificare i moduli selezionati ne tantomeno inserire altri moduli o modificare la sede
                    disableInput();

                    // metto in risalto i moduli selezionati
                    $('.moduli ').each(function () {
                        if ($(this).is(':checked')) {

                            $(this).parents('tr').css('background-color', '#d4edda');
                            console.log($(this).parents('tr'));
                        }
                    });

                    //metto in pagina un alert per spiegare all'utente che non sono più possibili modifiche

                    $('#alert-box').show();
                    $('#alertBox').empty();
                    $('#alertBox').html(
                        'I moduli evidenziati in verde sono stati confermati e possono essere inviati al sistema. Se si vuole apportare ulteriori modifiche alla rdr premere <button class="btn btn-primary" id="rdr-modify"> QUI </button>. Altrimenti premere su "crea rdr" in basso a destra.'
                    )


                } else if (pesoModuli > pesoMax) {
                    alert('Il peso dei moduli selezionati supera il massimo consentito di ' + pesoMax + '.');
                } else if (pesoModuli == 0) {
                    alert(`E' necessario selezionare almeno un modulo per verificare la richiesta di ritiro.`);
                }

            } else {
                alert('selezionare una sede di ritiro per proseguire.');
            }
        } else if ($('#sedeRitiroTerza').is(':checked')) {

            var pesoModuli = 0;

            if ($('#sedi_terze_option').val() != '') {
                // check sede moduli selezionati 
                var sedeTerzaId = $('#sedi_terze_option').val();

                $('.moduli ').each(function () {
                    if ($(this).is(':checked') && $(this).data('sede') == sedeTerzaId) {

                        pesoModuli += parseInt($(this).data('peso'));
                    }
                });

            }

            if (pesoModuli < pesoMax && pesoModuli != 0) {
                $('#verify-rdr').hide();
                $('#conf-rdr').show();

                // dopo la verifica non si possono modificare i moduli selezionati ne tantomeno inserire altri moduli o modificare la sede
                disableInput();

                // metto in risalto i moduli selezionati
                $('.moduli ').each(function () {
                    if ($(this).is(':checked') && $(this).data('sede') == sedeTerzaId) {

                        $(this).parents('tr').css('background-color', '#d4edda');
                    } else {
                        $(this).parents('tr').css('background-color', '#fffbdb');
                        $(this).prop('checked', false);
                    }
                });

                //metto in pagina un alert per spiegare all'utente che non sono più possibili modifiche

                $('#alert-box').show();
                $('#alertBox').empty();
                $('#alertBox').html(
                    'I moduli evidenziati in verde sono stati confermati e possono essere inviati al sistema. Se si vuole apportare ulteriori modifiche alla rdr premere <button class="btn btn-primary" id="rdr-modify"> QUI </button>. Altrimenti premere su "crea rdr" in basso a destra.'
                )


            } else if (pesoModuli > pesoMax) {
                alert('Il peso dei moduli selezionati supera il massimo consentito di ' + pesoMax + '.');
            } else if (pesoModuli == 0) {
                alert(`E' necessario selezionare almeno un modulo per verificare la richiesta di ritiro.`);
            }
        }
    });


    $(document).on('click', '#rdr-modify', function () {
        console.log('click');
        enableInput();


        $('.moduli').each(function () {

            $(this).parents('tr').css('background-color', 'white');
            console.log($(this).parents('tr'));
        });

    })

    function disableInput() {

        $('.moduli').attr('onClick', 'return false');

        $('input').attr('readonly', true);

        $('.radios').attr('onClick', 'return false');

        $('select').attr('readonly', true);

        $('select option:not(:selected)').attr('disabled', true);
    }

    function enableInput() {

        $('.moduli').removeAttr('onClick');

        $('input').removeAttr('readonly');

        $('.radios').removeAttr('onClick');

        $('select').removeAttr('readonly');

        $('select option:not(:selected)').removeAttr('disabled');

        $('#verify-rdr').show();
        $('#conf-rdr').hide();
        $('#alert-box').hide();

    }


});
